import React, { forwardRef, useRef } from 'react';
import { Footer2Button } from '../Components/Footers';
// import SportListItem from '../Components/SportListItem';
import MainNav from '../Components/MainNav.js';
import { useSportListForm } from '../queries/hooks';
import Loader from '../../components/Loader';
import { Form, Formio, Templates } from '@formio/react';
// import tailwind from '@tsed/tailwind-formio';
import { useMutateSubmitSportList } from '../queries/mutations';
import { useHistory, useParams } from 'react-router-dom';
import { currentFormNumber } from '../slices/registrationStepperSlice';
import { useDispatch } from 'react-redux';
import './SportSelectionStyle.css';

// // Formio.use(tailwind);
Formio.icons = 'fontawesome';
Templates.framework = 'tailwind';
export default function InstitutionSportsSelection() {
  // const genders = ['Male', "Female", "Mixed"];
  // const ageGroups = ['U-11', 'U-17', 'U-20'];

  let formioForm;

  function setForm(formio) {
    formioForm = formio;
  }

  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const errorRef = useRef(null);

  // const dat = [
  //   {
  //     img_url: '',
  //     event: 'Basket Ball',
  //     key: 'basket_ball',
  //   },
  // ];

  const data = useSportListForm({
    tournament_id: params.tournament_id,
  });

  const form = {
    components: [
      {
        label: 'Select Boxes',
        optionsLabelPosition: 'right',
        tableView: false,
        key: 'selectSports',
        type: 'selectboxes',
        input: true,
        inputType: 'checkbox',
        hideLabel: true,
        validate: {
          minSelectedCount: 1,
        },
      },
    ],
  };

  form.components =
    !data.isLoading &&
    form.components.map((item) => {
      if (item.type === 'selectboxes') {
        item.values = data?.data?.values?.map((el, i) => {
          return {
            label: `<div style='display:flex;justify-content:center;align-items:center;padding:12px' key=${i}><img style='height:22px;margin-right:10px;border-radius: 50%;' src=${el.image} alt=${el.label} /><div>${el.label}</div></div>`,
            value: el.value,
          };
        });
        console.log(item.values);
      }
      return item;
    });

  const submitForm = async (formData, next) => {
    const data = Object.keys(formData.data.selectSports).filter(
      (key) => formData.data.selectSports[key]
    );
    console.log('data', data);
    if (data.length > 0) {
      const d = await submitList({ sports: '' + data });
      console.log(d);
      if (d?.message?.toLowerCase() === 'success') {
        history.push(`/institution/registration/${params.tournament_id}/payment`);
      }
      next();
    } else {
      next('Please select at least one sport');
    }
  };

  const { mutateAsync: submitList } = useMutateSubmitSportList({
    onSuccess: (d) => {
      console.log('data', d);
    },
    onError: (error) => {
      console.log('error', error);
    },
  });

  return (
    <div>
      <MainNav />
      <div className="relative flex bg-gray-100 md:top-8 w-full md:w-9/12 font-roboto m-auto text-sm mb-10 md:mb-52">
        <div className="bg-white leading-2xl rounded-lg w-full  px-4  md:px-8 py-6 pt-2 md:pt-8 md:py-8 md:border border-gray-200 font-roboto">
          <h1 className="md:text-l text-base text-gray-750 font-bold mb-2">Selection of Sports</h1>
          <p className={'text-gray-650 font-bold text-xs md:text-sm mt-5'}>
            Selection of Sports is merely an expression of interest. This does not confirm or
            guarantee your participation in any of the selected Sports.
          </p>
          {/* <ul className={'list-disc list-inside ml-5 mb-5'}> */}
          {/*  <li className={'text-gray-650 text-xs md:text-sm'}> */}
          {/*    Guarantee of participation in those sports */}
          {/*  </li> */}
          {/*  <li className={'text-gray-650 text-xs md:text-sm'}> */}
          {/*    Change ability cannot add or remove sports in the future if you wish to */}
          {/*  </li> */}
          {/* </ul> */}
          <div className="text-base mt-4">
            {data.isLoading ? (
              <Loader />
            ) : (
              <>
                <div className={'text-red-500'} ref={errorRef} />
                <SportSelectionFormRenderer
                  submitForm={submitForm}
                  formReady={setForm}
                  data={form}
                />
              </>
            )}
            {/* Comment code kept for reference */}
            {/* <h2 className="text-2xl mt-8 mb-5">Basket Ball</h2>
                        <label className="ml-3">                    
                            <input type="checkbox" className="text-black border border-gray-400 mr-3" /> Temp
                        </label> */}
          </div>
        </div>
      </div>
      <Footer2Button
        hideBack={true}
        onPrevious={() => {
          dispatch(currentFormNumber());
          history.push(`/institution/registration/${params.tournament_id}/tournament-form`);
        }}
        onClick={() => formioForm.submit()}
      />
    </div>
  );
}

const SportSelectionFormRenderer = forwardRef(function f({ data, submitForm, ...props }, _ref) {
  return (
    <Form
      form={data}
      formReady={props.formReady}
      options={{
        hooks: {
          beforeSubmit: submitForm,
        },
      }}
    />
  );
});
