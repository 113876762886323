import { useMutation } from 'react-query';
import {
  addSportEventToCart,
  postTournamentDetails,
  removeCartItem,
  submitSportList,
  submitTournamentForm,
  submitTournamentFormCoach,
  setCart,
  coachVerificationRequest,
} from 'registration/services';

export const useMutateCartEvents = ({ onSuccess, onError, ...params }) => {
  return useMutation((data) => addSportEventToCart(data), {
    onSuccess: (result) => {
      typeof onSuccess && onSuccess(result);
    },
    onError: async (err) => {
      typeof onError && onError(err);
    },
    ...params,
  });
};

export const useMutateSubmitTournament = ({ onSuccess, onError }) => {
  return useMutation((data) => submitTournamentForm(data), {
    onSuccess: (result) => {
      typeof onSuccess && onSuccess(result);
    },
    onError: async (err) => {
      typeof onError && onError(err);
    },
  });
};

export const useMutateSubmitTournamentCoach = ({ onSuccess, onError }) => {
  return useMutation((data) => submitTournamentFormCoach(data), {
    onSuccess: (result) => {
      typeof onSuccess && onSuccess(result);
    },
    onError: async (err) => {
      typeof onError && onError(err);
    },
  });
};

export const useMutateRemoveCartItem = ({ onSuccess, onError, onSettled }) => {
  return useMutation((data) => removeCartItem(data), {
    onSuccess: (result) => {
      typeof onSuccess && onSuccess(result);
    },
    onError: async (err) => {
      typeof onError && onError(err);
    },
    onSettled: () => {
      typeof onSettled !== 'undefined' && onSettled();
    },
  });
};

export const useMutateSubmitSportList = ({ onSuccess, onError }) => {
  return useMutation((data) => submitSportList(data), {
    onSuccess: (result) => {
      typeof onSuccess && onSuccess(result);
    },
    onError: async (err) => {
      typeof onError && onError(err);
    },
  });
};

export const useMutateTournamentDetails = ({ onSuccess, onError }) => {
  return useMutation((data) => postTournamentDetails(data), {
    onSuccess: (result) => {
      typeof onSuccess && onSuccess(result);
    },
    onError: async (err) => {
      typeof onError && onError(err);
    },
  });
};

export const useMutateSetCart = ({ onSuccess, onError }) => {
  return useMutation((data) => setCart(data), {
    onSuccess: (result) => {
      typeof onSuccess && onSuccess(result);
    },
    onError: async (err) => {
      typeof onError && onError(err);
    },
  });
};

export const useMutateCoachVerificationRequest = ({ onSuccess, onError }) => {
  return useMutation((data) => coachVerificationRequest(data), {
    onSuccess: (result) => {
      typeof onSuccess && onSuccess(result);
    },
    onError: async (err) => {
      typeof onError && onError(err);
    },
  });
};
